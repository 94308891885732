<template>
  <div class="homepage-layout">
    <div class="component">
      <breadcrumbs
        v-if="breadcrumb && !wide">
        <breadcrumb
          :key="bc.id"
          :index="idx"
          :breadcrumb="bc"
          v-for="(bc, idx) in calculatedBreadcrumbs">
          {{ bc.name }}
        </breadcrumb>
      </breadcrumbs>
      <slot/>
    </div>
    <div class="mt-10 w-full">
      <content-section
        :key="index"
        :category="childCategory"
        v-for="(childCategory, index) in childCategories">
        <content-container
          layout="homepage"
          :category="childCategory"
          :disable-background="true"
          :show-preview="true">
        </content-container>
      </content-section>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'wide',
      'layout',
      'category',
      'breadcrumb',
    ],
    computed: {
      calculatedBreadcrumbs() {
        let baseBreadcrumb = this.category?.breadcrumbs?.length > 0
          ? `/${this.$helper.slugify(this.category?.breadcrumbs[0].name)}-${this.category?.breadcrumbs[0].id}/`
          : '/'

        if (this.category
          && this.category.breadcrumbs) {
          return this.category.breadcrumbs
          .filter((breadcrumb, index, self) => index > 1 && index < (self.length - 1))
          .map((breadcrumb, index) => {
            if (index > 0) {
              baseBreadcrumb += '/'
            }
            baseBreadcrumb += `${this.$helper.slugify(breadcrumb.name)}-${breadcrumb.id}`
            return {
              id: breadcrumb.id,
              name: breadcrumb.name,
              link: baseBreadcrumb,
            }
          })
        }
      }
    },
    data() {
      return {
        childCategories: null
      }
    },
    async created() {
      if (this.category.childrencount > 0) {
        this.childCategories = await this.$api.findCategories(this.category.id)
      }
    },
    methods: {
      findImageURL(category) {
        let media = category.attributes?.find(attr => attr.name === 'Media')
        if (!media || !media.inherited) {
          return category?.media?.links?.find(l => l.rel === 'download')?.href
        }
      },
      async matchVueComponent(category) {
        let templateAttr = category.attributes.find(attr => attr.name === 'CMS-Template')
        let component = this.$helper.resolveComponentTypeByCategory(category, templateAttr)
        return this.$helper.resolveComponents([component], 'page')[0].vueComponent
      }
    },
    inject: [
      '$api',
      '$helper'
    ]
  }
</script>

<style lang="scss" scoped>
  .homepage-layout {
    @apply
    flex
    w-full
    flex-col
    min-h-full
    items-center
    overflow-y-auto;

    .component {
      @apply
        w-full
        md:pt-12
        max-w-full
        min-h-full
        md:max-w-5xl;
    }
  }
</style>